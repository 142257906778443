import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import './index.css';
import store from './redux/store';
import { Theme } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import { Auth0Provider } from '@auth0/auth0-react';
import { createBrowserHistory } from 'history';
import reportWebVitals from './reportWebVitals';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}
const onRedirectCallback = (appState: any) => {
  createBrowserHistory().push(
    appState && appState.returnTo
      ? appState.returnTo
      : window.location.pathname,
  );
};
const providerConfig = {
  domain: `${process.env.REACT_APP_AUTH0_DOMAIN}`,
  clientId: `${process.env.REACT_APP_AUTH0_CLIENTID}`,
  redirectUri: window.location.origin,
  audience: `https://${process.env.REACT_APP_AUTH0_AUDIENCE}`,
  scope: 'profile email read:permissions',
  onRedirectCallback,
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <CssBaseline />
        <Auth0Provider {...providerConfig}>
          <App />
        </Auth0Provider>
      </StyledEngineProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
