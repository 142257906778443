export const MODAL_ACTION = {
  SHOW_USER_ACTION_MODEL: 'SHOW_USER_ACTION_MODEL',
  SHOW_NOTIFICATION_MODEL: 'SHOW_NOTIFICATION_MODAl',
  SHOW_ADD_CREDITS_MODEL: 'SHOW_ADD_CREDITS_MODAl',
  SHOW_USER_UPDATE_MODEL: 'SHOW_USER_UPDATE_MODEL',
  SHOW_CONTACT_MODEL: 'SHOW_CONTACT_MODEL',
  SHOW_RESET_PASSWORD_MODEL: 'SHOW_RESET_PASSWORD_MODEL',
  SHOW_AVATARS_MODEL: 'SHOW_AVATARS_MODEL',
  SHOW_CLOSE_ACCOUNT_MODEL: 'SHOW_CLOSE_ACCOUNT_MODEL',
  SHOW_MERGE_ACCOUNT_MODEL: 'SHOW_MERGE_ACCOUNT_MODEL',
  SHOW_UPDATE_TRANSACTION_MODEL: 'SHOW_UPDATE_TRANSACTION_MODEL',
  SHOW_UPSERT_CURRENCY_TYPE_MODEL: 'SHOW_UPSERT_CURRENCY_TYPE_MODEL',
  SHOW_UPDATE_SUCCESS_MODEL: 'SHOW_UPDATE_SUCCESS_MODEL',
  SHOW_CREATE_TRANSACTION_MODEL: 'SHOW_CREATE_TRANSACTION_MODEL',
  SHOW_CREATE_SUCCESS_MODEL: 'SHOW_CREATE_SUCCESS_MODEL',
  SHOW_UPDATE_FAILED_MODEL: 'SHOW_UPDATE_FAILED_MODEL',
  SHOW_DELETE_TRANSACTION_MODEL: 'SHOW_DELETE_TRANSACTION_MODEL',
  SHOW_REMOVE_TRANSACTION_MODEL: 'SHOW_REMOVE_TRANSACTION_MODEL',
  SHOW_CREATE_PROMOCODE_MODEL: 'SHOW_CREATE_PROMOCODE_MODEL',
  SHOW_CREATE_PROMOTYPE_MODEL: 'SHOW_CREATE_PROMOTYPE_MODEL',
};
