/* eslint-disable indent */
import React, { ReactElement } from 'react';
import {
  Route,
  Switch,
} from 'react-router';
import { openRoute } from '../constants/routeEnum';

import Forgot from '../container/Forgot/Forgot';
import Reset from '../container/Reset/Reset'
import 'react-toastify/dist/ReactToastify.css';
import PageNotFound from '../component/PageNotFound';
import {  ToastContainer } from 'react-toastify';

const Routes = (): ReactElement => {
  return (
    <>
      <Switch>
        <Route path={openRoute.forgot} exact component={Forgot} />
        <Route path={openRoute.reset} exact component={Reset} />
        <Route exact path={openRoute.fourOfour} component={PageNotFound} />
      </Switch>
      <ToastContainer />
    </>
  );
};

export default Routes;
