import { ThemeProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import React, { createContext, ReactElement, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import ThemeContext from './context';
import Routes from './routes/Routes';
import { createTheme } from '@mui/material/styles';
import {
  game_theme,
  common_theme,
} from './utils/theme';
import { Loader } from './component/Loader/Loader';
const globalAny: any = global;
LicenseInfo.setLicenseKey(`${process.env.REACT_APP_MUI_PRO_KEY}`);

interface LoadingContextType {
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>> | undefined;
}

const App: React.FC = () => {
  const { isLoading, error } = useAuth0();
  const [themeInfo, setThemeInfo] = useState<any>('common_theme');

  const getLocalValue = () => {
    return localStorage.getItem('theme');
  };

  useEffect(() => {
    const data = getLocalValue();
    setThemeInfo(data || 'common_theme');
  }, []);
  if (error) {
    return <div>Oops... {error.message}</div>;
  }
  if (isLoading) {
    return <Loader open={isLoading} />;
  }

  const LoadingContext = createContext<LoadingContextType>({
    loading: false,
    setLoading: undefined,
  });

  function LoadingProvider(props: {
    children:
      | boolean
      | React.ReactChild
      | React.ReactFragment
      | React.ReactPortal;
  }): ReactElement {
    const [loading, setLoading] = useState(false);
    const value = { loading, setLoading };
    return (
      <LoadingContext.Provider value={value}>
        {props.children}
        {loading && <Loader open={isLoading} />}
      </LoadingContext.Provider>
    );
  }

  const themes: any = {
    game_theme,
    common_theme,
  };

  const currentTheme = themes[themeInfo];

  return (
    <ThemeContext.Provider value={[themeInfo, setThemeInfo]}>
      <ThemeProvider theme={createTheme(currentTheme)}>
        <BrowserRouter>
          <LoadingProvider>
            <LoadingContext.Consumer>
              {(funcs: any) => {
                globalAny.props = { ...funcs };
                return <Routes />;
              }}
            </LoadingContext.Consumer>
          </LoadingProvider>
        </BrowserRouter>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default App;
