import { Reducer } from 'redux';
import { MODAL_ACTION } from '../../actions/modalActions/modelType';
import { MODAL_STATE } from '../../actions/type';

export interface ModelState {
  showUserAction: {
    show: boolean;
    data: any;
  };
  showNotification: {
    show: boolean;
    data: any;
  };
  showAddCredit: {
    show: boolean;
    data: any;
  };
  showUserUpdate: {
    show: boolean;
    data: any;
  };
  showContact: {
    show: boolean;
    data: any;
  };
  showResetPassword: {
    show: boolean;
    data: any;
  };
  showAvatars: {
    show: boolean;
    data: any;
  };
  showCloseAccount: {
    show: boolean;
    data: any;
  };
  showMergeAccount: {
    show: boolean;
    data: any;
  };
  showUpdateTransaction: {
    show: boolean;
    data: any;
  };
  showUpsertCurrencyType: {
    show: boolean;
    data: any;
  };
  showUpdateSuccess: {
    show: boolean;
    data: any;
  };
  showUpdateFailed: {
    show: boolean;
    data: any;
  };
  showDeleteTransaction: {
    show: boolean;
    data: any;
  };
  showRemoveTransaction: {
    show: boolean;
    data: any;
  };
  showCreateTransaction: {
    show: boolean;
    data: any;
  };
  showCreateSuccess: {
    show: boolean;
    data: any;
  };
  showCreatePromoCode: {
    show: boolean;
    data: any;
  };
  showCreatePromoType: {
    show: boolean;
    data: any;
  };
}

export const modalReducer: Reducer<ModelState> = (
  state = MODAL_STATE,
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case MODAL_ACTION.SHOW_USER_ACTION_MODEL:
      return {
        ...state,
        showUserAction: {
          ...state.showUserAction,
          show: payload.isVisible,
          data: payload.userData,
        },
      };
    case MODAL_ACTION.SHOW_NOTIFICATION_MODEL:
      return {
        ...state,
        showNotification: {
          ...state.showNotification,
          show: payload.isVisible,
          data: payload.userData,
        },
      };
    case MODAL_ACTION.SHOW_ADD_CREDITS_MODEL:
      return {
        ...state,
        showAddCredit: {
          show: payload.isVisible,
          data: payload.userData,
        },
      };
    case MODAL_ACTION.SHOW_USER_UPDATE_MODEL:
      return {
        ...state,
        showUserUpdate: {
          ...state.showUserUpdate,
          show: payload.isVisible,
          data: payload.userData,
        },
      };
    case MODAL_ACTION.SHOW_CONTACT_MODEL:
      return {
        ...state,
        showContact: {
          ...state.showContact,
          show: payload.isVisible,
          data: payload.userData,
        },
      };
    case MODAL_ACTION.SHOW_RESET_PASSWORD_MODEL:
      return {
        ...state,
        showResetPassword: {
          ...state.showResetPassword,
          show: payload.isVisible,
          data: payload.userData,
        },
      };
    case MODAL_ACTION.SHOW_AVATARS_MODEL:
      return {
        ...state,
        showAvatars: {
          ...state.showAvatars,
          show: payload.isVisible,
          data: payload.userData,
        },
      };
    case MODAL_ACTION.SHOW_CLOSE_ACCOUNT_MODEL:
      return {
        ...state,
        showCloseAccount: {
          ...state.showCloseAccount,
          show: payload.isVisible,
          data: payload.userData,
        },
      };
    case MODAL_ACTION.SHOW_MERGE_ACCOUNT_MODEL:
      return {
        ...state,
        showMergeAccount: {
          ...state.showMergeAccount,
          show: payload.isVisible,
          data: payload.userData,
        },
      };
    case MODAL_ACTION.SHOW_UPDATE_TRANSACTION_MODEL:
      return {
        ...state,
        showUpdateTransaction: {
          ...state.showUpdateTransaction,
          show: payload.isVisible,
          data: payload.userData,
        },
      };
    case MODAL_ACTION.SHOW_UPSERT_CURRENCY_TYPE_MODEL:
      return {
        ...state,
        showUpsertCurrencyType: {
          ...state.showUpsertCurrencyType,
          show: payload.isVisible,
          data: payload.userData,
        },
      };
    case MODAL_ACTION.SHOW_UPDATE_SUCCESS_MODEL:
      return {
        ...state,
        showUpdateSuccess: {
          ...state.showUpdateSuccess,
          show: payload.isVisible,
          data: payload.userData,
        },
      };
    case MODAL_ACTION.SHOW_UPDATE_FAILED_MODEL:
      return {
        ...state,
        showUpdateFailed: {
          ...state.showUpdateFailed,
          show: payload.isVisible,
          data: payload.userData,
        },
      };
    case MODAL_ACTION.SHOW_CREATE_TRANSACTION_MODEL:
      return {
        ...state,
        showCreateTransaction: {
          ...state.showCreateTransaction,
          show: payload.isVisible,
          data: payload.userData,
        },
      };
    case MODAL_ACTION.SHOW_CREATE_SUCCESS_MODEL:
      return {
        ...state,
        showCreateSuccess: {
          ...state.showCreateSuccess,
          show: payload.isVisible,
          data: payload.userData,
        },
      };
    case MODAL_ACTION.SHOW_DELETE_TRANSACTION_MODEL:
      return {
        ...state,
        showDeleteTransaction: {
          ...state.showDeleteTransaction,
          show: payload.isVisible,
          data: payload.userData,
        },
      };

    case MODAL_ACTION.SHOW_REMOVE_TRANSACTION_MODEL:
      return {
        ...state,
        showRemoveTransaction: {
          ...state.showDeleteTransaction,
          show: payload.isVisible,
          data: payload.userData,
        },
      };

    case MODAL_ACTION.SHOW_CREATE_PROMOCODE_MODEL:
      return {
        ...state,
        showCreatePromoCode: {
          ...state.showCreatePromoCode,
          show: payload.isVisible,
          data: payload.userData,
        },
      };

    case MODAL_ACTION.SHOW_CREATE_PROMOTYPE_MODEL:
      return {
        ...state,
        showCreatePromoType: {
          ...state.showCreatePromoType,
          show: payload.isVisible,
          data: payload.userData,
        },
      };
    default:
      return state;
  }
};
