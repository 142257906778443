export const game_theme = {
  palette: {
    primary: {
      light: '#ffac33',
      main: '#ff9800',
      dark: '#b26a00',
      contrastText: '#000000',
    },
    secondary: {
      light: '#e666fb',
      main: '#e040fb',
      dark: '#9c2caf',
      contrastText: '#fcfcfc',
    },
    info: {
      light: '#83ffe1',
      main: '#64ffda',
      dark: '#46b298',
    },
    warning: {
      light: '#ffff33',
      main: '#ffff00',
      dark: '#b2b200',
    },
    error: {
      light: '#ff7474',
      main: '#ff5252',
      dark: '#b23939',
    },
    success: {
      light: '#6fbf73',
      main: '#4caf50',
      dark: '#357a3',
    },
    background: {
      default: '#b26a00',
    },
  },
  typography: {},
};

export const common_theme = {
  palette: {
    primary: {
      light: '#ffac33',
      main: '#ff9800',
      dark: '#b26a00',
      contrastText: '#000000',
    },
    secondary: {
      light: '#e666fb',
      main: '#e040fb',
      dark: '#9c2caf',
      contrastText: '#fcfcfc',
    },
    info: {
      light: '#83ffe1',
      main: '#64ffda',
      dark: '#46b298',
    },
    warning: {
      light: '#ffff33',
      main: '#ffff00',
      dark: '#b2b200',
    },
    error: {
      light: '#ff7474',
      main: '#ff5252',
      dark: '#b23939',
    },
    success: {
      light: '#6fbf73',
      main: '#4caf50',
      dark: '#357a3',
    },
    background: {
      default: '#b26a00',
    },
  },
  typography: {},
};
