/* eslint-disable indent */
export const composeValidators = (...validators: any[]) => {
  return (value: string): any => {
    return validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );
  };
};

export const required = (value: string | number): string | undefined =>
  value && String(value).trim() !== "" ? undefined : "Required";
export const isValidEmail = (value: string): string | false =>
  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) &&
  "Please Provide valid email address";

export const objectKeyLength = (value: string): string | undefined =>
  value && Object.keys(value).length !== 0 ? undefined : "Required";

export const isUpperCase = (value: string): string | undefined =>
  value && value.toUpperCase() === value ? undefined : "Must be uppercase";

export const maxLength =
  (length: number) =>
  (value: string): string | undefined =>
    value && value.trim().length > length
      ? `Must be ${length} characters or less`
      : undefined;

export const minLength =
  (length: number) =>
  (value: string): string | undefined =>
    value && value.trim().length < length
      ? `Must be ${length} characters or more`
      : undefined;

export const confirmPasswordsMatch =
  (password: string) =>
  (value: string): string | undefined =>
    value && value === password ? undefined : "Your passwords do not match";

export const DateRequired =
  (endDate: any) =>
  (value: string): string | undefined =>
    value && value >= endDate ? "Must be less than end date" : undefined;
