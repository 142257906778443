import { Button, Grid, Paper, Typography } from "@mui/material";
import { InputAdornment } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { Field } from "react-final-form";
import InputWrapper from "../../component/UI/Input";
import { Email, Lock } from "@mui/icons-material";
import {
  composeValidators,
  maxLength,
  minLength,
  confirmPasswordsMatch,
  required,
} from "../../utils/validations";
const PREFIX = "FormForgotPassword";

const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`,
};

const StyledGrid = styled(Grid)(() => ({
  [`& .${classes.root}`]: {
    padding: "20px 30px 30px 30px",
    margin: "auto",
    width: "100%",
    marginTop: "10%",
    backgroundColor: "#ffffff",
  },

  [`& .${classes.button}`]: {
    marginTop: "10px",
    display: "inline-block",
    backgroundColor: "black",
    color: "white",
    fontWeight: "700",
  },
}));

interface FormLogin {
  forgotSubmit: (values: any) => void;
  email: string;

}

const FormForgotPassword: React.FC<FormLogin> = (props: FormLogin) => {
  const { forgotSubmit } = props;
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const getParam = (param: string) => {
    return new URLSearchParams(window.location.search).get(param);
  };


  const handlePassword = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setPassword(value);
  };

  useEffect(() => {
    let playerEmail:any = getParam("mail");
    playerEmail = playerEmail ? playerEmail : ''
    console.log({ playerEmail });

    
    setEmail(playerEmail);
  }, []);
  return (
    <StyledGrid container justifyContent="center" alignItems="center">
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <Paper className={classes.root} elevation={4}>
        <Grid
            container
            alignItems="center"
            justifyContent="center"
            flexDirection={"column"}
          >
            <div>
              <img
                src={`${process.env.REACT_APP_BASE_URL}/images/skillify-logo.png`}
                width="100%"
                height="150px"
                alt='Header Image'
              />
            </div>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            flexDirection={"column"}
          >
            <Typography
              variant="h3"
              component="h2"
              align="center"
              sx={{ color: "black" }}
            >
              Reset Password
            </Typography>
          </Grid>
          <Form onSubmit={forgotSubmit}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className="grey-text" style={{ marginTop: "20px" }}>
                  <Field
                    label="Account Email:"
                    startAdornment={
                      <InputAdornment position="start">
                        <Email style={{ color: "black" }} />
                      </InputAdornment>
                    }
                    type="text"
                    name="email"
                    defaultValue={email}
                    value={email}
                    initialValue={email}
                    disabled={true}
                    component={InputWrapper}
                  />
                </div>

                <div className="grey-text" style={{ marginTop: "20px" }}>
                  <Field
                    label="Please enter a new password"
                    startAdornment={
                      <InputAdornment position="start">
                        <Lock style={{ color: "black" }} />
                      </InputAdornment>
                    }
                    type="password"
                    name="password"
                    onChange={handlePassword}
                    component={InputWrapper}
                    validate={composeValidators(required, minLength(6), maxLength(20))}
                  />
                </div>

                <div className="grey-text" style={{ marginTop: "20px" }}>
                  <Field
                    label="Please confirm the new password"
                    startAdornment={
                      <InputAdornment position="start">
                        <Lock style={{ color: "black" }} />
                      </InputAdornment>
                    }
                    type="password"
                    name="confirmPassword"
                    component={InputWrapper}
                    validate={composeValidators(required, minLength(6), maxLength(20),confirmPasswordsMatch(password))}
                  />
                </div>

                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  flexDirection={"column"}
                >
                  <Button
                    color="primary"
                    type="submit"
                    variant="contained"
                    className={classes.button}
                    size="large"
                    fullWidth
                  >
                    Reset Password
                  </Button>
                </Grid>
              </form>
            )}
          </Form>
        </Paper>
      </Grid>
    </StyledGrid>
  );
};

export default FormForgotPassword;
