// All un-protected routes will be defined in here
export enum openRoute {
  forgot = '/',
  reset = '/reset',
  fourOfour = '/not-found',
}

// All protected routes will be defiend
export enum protectedRoutes {
  home = '/home',
  example = '/example',
  partnerLandingPage = '/partnerLandingPage'
}

// Any new user access need tobe added to the system need to pass a string element in here to give permission
export enum userAccess {
  admin = 'admin',
  test = 'test',
}
