import React, { useEffect, useState } from "react";
import FormReset from "./FormReset";
import { ResetSubmit } from "../../utils/Interfaces/forgot";
import { RouteComponentProps } from "react-router-dom";
import axiosInstance from "../../utils/apis";

import { toast } from "react-toastify";
import { API_URLS } from "../../constants/url";
type ChildComponentProps = RouteComponentProps;

const Login: React.FC<ChildComponentProps> = () => {
  const [email, setEmail] = useState<string>("Test");
  const [accessKey, setAccessKey] = useState<string>("Test");

  const getParam = (param: string) => {
    return new URLSearchParams(window.location.search).get(param);
  };

  const ResetSubmit = async (value: ResetSubmit) => {
    const token = localStorage.getItem('access_key')
    const submitForgotPasswordRequest: any = await axiosInstance.post(
      API_URLS.RESET_PASSWORD + `/${token}`,
      {
        password: value.password,
        confirm: value.confirmPassword,
        partner: process.env.Partner,
      }
    );
    console.log(submitForgotPasswordRequest);

    toast.success("Your Password has been successfully Updated.");
  };

  useEffect(() => {
    let email: any = getParam("mail");
    let secret: any = getParam("secret");
    email = email ? email : "";
    secret = secret ? secret : "";
    console.log({ email, secret });

    setEmail(email);
    setAccessKey(secret);
    localStorage.setItem("access_key", secret);
  }, []);

  return (
    <div>
      <FormReset forgotSubmit={ResetSubmit} email={email} />
    </div>
  );
};

export default Login;
