import { Reducer } from 'redux';
import { ReducerState } from '../../utils/Interfaces/common';
import {
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGIN_REQUEST,
  LOGOUT_ACTION,
} from '../actions/Authentication/authType';
import { DATA_DEFAULT, LOGIN_DEFAULT } from '../actions/type';

export interface LoginState extends ReducerState {
  loginSuccess: boolean;
  data: {
    success: boolean;
    signon_token: string;
    gameId: number | null;
    gameName: string;
    userRedeemCount: number | null;
    userId: number | null;
    userName: string;
    mail: string;
    funds: string;
    bonus_cash: string;
    token: string;
    avatar: string;
    ticket: number | string | null;
    remainingClaim: number | null;
    login_count: {
      nextTokenAmount: number | null;
      nextTokenTime: number | null;
    };
    gToken: number | null;
    msg: string;
    guest: boolean | null;
    age_verification_provider: string | null;
    age_verification_timestamp: string | null;
    role: string;
    permissions: Array<string>;
  };
  success: boolean;
  loading: boolean;
  errMsg: string;
}

export const loginReducer: Reducer<LoginState> = (
  state = LOGIN_DEFAULT,
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_REQUEST:
      return { ...state, loading: true, errMsg: '' };
    case LOGIN_SUCCESS:
      localStorage.setItem('currentUser', JSON.stringify(payload));
      return {
        ...state,
        loading: false,
        data: payload,
        loginSuccess: true,
        success: true,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        data: DATA_DEFAULT,
        loginSuccess: false,
        success: false,
        errMsg: action.error.response?.data?.message || action.error?.data,
      };
    case LOGOUT_ACTION:
      return {
        ...state,
        loading: false,
        data: DATA_DEFAULT,
        loginSuccess: false,
        success: false,
      };

    default:
      return state;
  }
};
