import { combineReducers } from 'redux';
import { loginReducer } from './authReducer';
import { commonReducers } from './commonReducers';
import { modalReducer } from './ModalReducer';

const rootReducer = combineReducers({
  loginReducer: loginReducer,
  commonReducers: commonReducers,
  modalReducer,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
