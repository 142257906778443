export const URLS = {
  API_URL: `${process.env.REACT_APP_API_URL}/`,
  WW_ADMIN_TOOL_URL: 'https://dev-pay-admin.worldwinner.com/api/admintools/',
  DEVELOPMENT_BASE_URL: 'http://localhost:3000/api/development/',
};

export const API_URLS = {
  REQUEST_PASSWORD_RESET: URLS.API_URL + 'forgot',
  RESET_PASSWORD: URLS.API_URL + 'reset'
};
