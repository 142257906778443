import { Button, Grid, Paper, Typography } from "@mui/material";
import { InputAdornment } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { Form } from "react-final-form";
import { Field } from "react-final-form";
import InputWrapper from "../../component/UI/Input";
import { Email } from "@mui/icons-material";
import {
  composeValidators,
  isValidEmail,
  required,
} from "../../utils/validations";
const PREFIX = "FormForgotPassword";

const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`,
};

const StyledGrid = styled(Grid)(() => ({
  [`& .${classes.root}`]: {
    padding: "40px 30px 30px 30px",
    margin: "auto",
    width: "100%",
    marginTop: "10%",
    backgroundColor: "#ffffff",
  },

  [`& .${classes.button}`]: {
    marginTop: "10px",
    display: "inline-block",
    backgroundColor: "black",
    color: "white",
    fontWeight: "700",
  },
}));

interface FormLogin {
  forgotSubmit: (values: any) => void;
}

const FormForgotPassword: React.FC<FormLogin> = ({ forgotSubmit }) => {
  return (
    <StyledGrid container justifyContent="center" alignItems="center">
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <Paper className={classes.root} elevation={4}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            flexDirection={"column"}
          >
            <div>
              <img
                src={`${process.env.REACT_APP_BASE_URL}/images/skillify-logo.png`}
                width="100%"
                height="150px"
              />
            </div>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            flexDirection={"column"}
          >
            <Typography
              variant="h3"
              component="h2"
              align="center"
              sx={{ color: "black" }}
            >
              Forget your password?
            </Typography>
          </Grid>
          <Form onSubmit={forgotSubmit}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className="grey-text" style={{ marginTop: "20px" }}>
                  <Field
                    label="Please enter the email linked to your account:"
                    startAdornment={
                      <InputAdornment position="start">
                        <Email style={{ color: "black" }} />
                      </InputAdornment>
                    }
                    type="text"
                    name="email"
                    component={InputWrapper}
                    validate={composeValidators(required, isValidEmail)}
                  />
                </div>

                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  flexDirection={"column"}
                >
                  <Button
                    color="primary"
                    type="submit"
                    variant="contained"
                    className={classes.button}
                    size="large"
                    fullWidth
                  >
                    Request Password Reset
                  </Button>
                </Grid>
              </form>
            )}
          </Form>
        </Paper>
      </Grid>
    </StyledGrid>
  );
};

export default FormForgotPassword;
