import React from "react";
import FormLogin from "./FormForgot";
import { ForgotSubmit } from "../../utils/Interfaces/forgot";
import { RouteComponentProps } from "react-router-dom";
import axiosInstance from "../../utils/apis";

import { toast } from "react-toastify";
import { API_URLS } from "../../constants/url";
type ChildComponentProps = RouteComponentProps;

const Login: React.FC<ChildComponentProps> = () => {
  const forgotSubmit = async (value: ForgotSubmit) => {
    const submitForgotPasswordRequest: any = await axiosInstance.post(
      API_URLS.REQUEST_PASSWORD_RESET,
      {
       email: value.email,
       partner:"Skillify",
       data: {email: value.email, partner: "Skillify"} ,
      }
    );
    console.log(submitForgotPasswordRequest);

    toast.success("An email has been sent with a link to reset your password.");
  };

  return (
    <div>
      <FormLogin forgotSubmit={forgotSubmit} />
    </div>
  );
};

export default Login;
